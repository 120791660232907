import React from "react";

const TeamSection = () => {

  return (
    <div className="dds-team-container">
      <label style={{fontSize: "20px"}}>{"<Team>"}</label>
      <div className="dds-team-section-text">
        <h4>Talent</h4>
        <p>We’ve taken some of the brightest, young and balanced it with the experience and knowledge base of an international management team.</p>
        <p>Our objective is to offer high quality, value for money services to our clients and develop lasting relationships.</p>
        <h4>Why choose us?</h4>
        <p>We are dedicated to delivering solutions that meet our client’s brief. Our cost effective model allows us to spend more time on prototyping and refinement (something customers always want) without sending your budget out of control. </p>
        <p>Most importantly, we work tirelessly to deliver exceptional solutions. If we need to learn something new to solve a problem or go the extra mile, we’ll do it.</p>
      </div>
      <div className="dds-close-team">
        <label style={{fontSize: "20px"}}>{"</Team>"}</label>
      </div>
    </div>
  );
}

export default TeamSection;
