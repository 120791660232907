import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx"

const TopBar = () => {

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // var home = document.getElementById("home-menu-button")
    // home.style.opacity = 1
    goToSections()
  });

  const goToSections = () => {
    var sectionName = location.state ? location.state.component : "";

    if(sectionName !== "") {
      var sectionComponent = document.getElementById(sectionName);
      if(sectionComponent) {
        // if(sectionName !== "dds-about-section") {
          sectionComponent.scrollIntoView();
        // } else {
        //   container[0].scrollTop = sectionComponent.offsetTop - 150
        // }
      }
    }
  }

  const goToPage = (url) => {
    if(window.screen.width <= 800) {
      openMenuBar()
    }
    navigate(url)
  }

  const menuClick = (e) => {
    var container = document.getElementsByClassName("dds-homepage-container")
    var sectionName = "dds-" + e.target.getAttribute("name") + "-section";
    var sectionComponent = document.getElementById(sectionName);

    if(window.screen.width <= 800) {
      openMenuBar()
    }

    if(location.pathname !== "/job" && location.pathname !== "/portfolio") {
      if(sectionComponent) {
        // if(sectionName !== "dds-about-section") {
          // sectionComponent.scrollIntoView();
        // } else {
          container[0].scrollTop = sectionComponent.offsetTop - 85
        // }
      }
    } else {
      navigate("/", {state: {component: sectionName}})
    }
  };

  const openMenuBar = () => {
    var menu = document.getElementsByClassName("dds-topbar-menu-container")
    var topbar = document.getElementsByClassName("dds-topbar-container")

    if(menu[0].getAttribute("class") === "dds-topbar-menu-container") {
      menu[0].setAttribute("class", "dds-topbar-menu-container set-flex-active")
      topbar[0].style.borderColor = "#949A9C"
    } else {
      menu[0].setAttribute("class", "dds-topbar-menu-container")
      topbar[0].style.borderColor = "#006D81"
    }
  }

  return (
    <>
      <div className="dds-topbar-container">
        <div className="dds-topbar-content">
          <a href="https://devapps.datadrivensystems.co.uk/dds-website/">
            <div className="dds-topbar-logo">
              <img
                src="https://res.cloudinary.com/datadriven/image/upload/v1448610033/DDS/logo.png"
                alt="dds-logo"
              />
            </div>
          </a>
          <div className="dds-topbar-hamburger" onClick={() => openMenuBar()}>
            <RxHamburgerMenu />
          </div>
          <div className="dds-topbar-menu-container">
            <div
              id="home-menu-button"
              className="dds-topbar-menu"
              name="home"
              onClick={(e) => menuClick(e)}
            >
              {"<Home>"}
            </div>
            <div
              id="about-menu-button"
              className="dds-topbar-menu"
              name="about"
              onClick={(e) => menuClick(e)}
            >
              {"<About>"}
            </div>
            <div className="dds-topbar-menu" onClick={() => goToPage("/portfolio")}>{"<Portofolio>"}</div>
            <div
              id="team-menu-button"
              className="dds-topbar-menu"
              name="team"
              onClick={(e) => menuClick(e)}
            >
              {"<Team>"}
            </div>
            <div
              id="contact-menu-button"
              className="dds-topbar-menu"
              name="contact"
              onClick={(e) => menuClick(e)}
            >
              {"<Contact>"}
            </div>
            <div className="dds-topbar-menu" onClick={() => goToPage("/job")}>{"<Jobs>"}</div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default TopBar;
