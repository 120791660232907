import React from "react";
import FooterSection from "../component/FooterSection";
import { useNavigate } from "react-router-dom";

const JobsPage = () => {

  const navigate = useNavigate()

  const goToContact = () => {
    navigate("/", {state: {component: "dds-contact-section"}})
  }

  return (
    <div className="dds-jobspage-container">
      <div className="dds-jobspage-content">
        <h3>Career Oportunities</h3>
        <div style={{padding: "1rem"}}/>
        <p>We are always open for skilled and enthusiastic job applicants.</p>
        <p>Send us your resume along with your portfolio and tell us why you'd be a good match for us.</p>
        <div style={{padding: "1rem"}}/>
        <h5>Full Time Web Programmer</h5>
        <div style={{padding: "1rem"}}/>
        <p>Skills required:</p>
        <p style={{lineHeight: "1.5"}}>
          - Expert in Javascript <br />
          - Expert in CSS <br />
          - jQuery <br />
          - Bootstrap <br />
        </p>
        <div style={{padding: "1rem"}}/>
        <h5>Full Time Web Designer</h5>
        <div style={{padding: "1rem"}}/>
        <p>Skills required:</p>
        <p style={{lineHeight: "1.5"}}>
          - CSS3 <br />
          - HTML5 <br />
          - PHP <br />
          - Javascript <br />
          - Bootstrap <br />
        </p>
        <div style={{padding: "1rem"}}/>
        <p>Location: Semarang</p>
        <p>Work hours: Mon-Fri (10am-7pm)</p>
        <div style={{padding: "1rem"}}/>
        <p>Final Year and Recent Graduates are welcome to apply</p>
        <div style={{padding: "1rem"}}/>
        <p>Please send CV & Portfolio to : <label className="dds-jobs-link-contact" onClick={() => goToContact()}>Contact</label></p>
      </div>
      <FooterSection />
    </div>
  );
}

export default JobsPage;
