import React from "react";

const AboutSection = () => {

  return (
    <div className="dds-the-about-container">
      <label style={{fontSize: "20px"}}>{"<About>"}</label>
      <div className="dds-about-section-text">
        <h3>DATA DRIVEN SYSTEMS</h3>
        <p>At Data Driven Systems we have put together a team of highly skilled developers whose objective is to offer web based business solutions built on strong data orientated foundations. We aim to display data from a variety of different programs in dashboards and tables, providing you with invaluable continuity and flexibility.</p>
        <p>We love working with data but our team’s ability does not end there. Understanding that our clients need a range of different skills and services, we have ensured that we have the right people to offer flexible, high quality solutions to their online needs, covering a range of important areas.</p>
      </div>
    </div>
  );
}

export default AboutSection;
