import React from "react";
import { FaLaptop, FaPaintBrush, FaShoppingCart } from 'react-icons/fa';

const AppLineSection = () => {

  return (
    <div className="dds-the-about-container">
      <div className="dds-appline-content">
        <div className="section-the-lines" />
        <div className="dds-appline-content-text">
          <div className="section-appline">The user experience defines what your visitors do on your site, how they interact with you as a business and how long you retain their attention. Our user focused approach puts your customers’ needs at the core of your site whilst keeping your brand a business objectives firmly in mind</div>
          <div className="section-appline" />
          <div className="dds-appline-title-icon-box">
            <div className="dds-appline-circle-icon">
              <FaPaintBrush />
            </div>
            <div className="appline-circle-text">Web Design & UX</div>
          </div>
        </div>
        <div className="dds-appline-content-text">
          <div className="section-appline" />
          <div className="section-appline">We've got the in-house skills to build technically complex, distinctive applications. Our developers have created everything from mobile apps and eCommerce sites, to crawling, price checking and data processing systems. Plus, with Data Driven Systems, you'll get 12 months' free technical support and guarantees as standard.</div>
          <div className="dds-appline-title-icon-box">
            <div className="dds-appline-circle-icon">
              <FaLaptop />
            </div>
            <div className="appline-circle-text second-place">Web Development</div>
          </div>
        </div>
        <div className="dds-appline-content-text">
          <div className="section-appline">With over 10 years experience developing and managing eCommerce platforms at the highest level we know how to produce an optimised, bespoke system that allows you to focus on the business issues that matter to you. We can offer solutions or support on everything from web development (incl. Magento), bespoke RMA, customer service management & training, inventory management to simply logo & UI design.</div>
          <div className="section-appline" />
          <div className="dds-appline-title-icon-box">
            <div className="dds-appline-circle-icon">
              <FaShoppingCart />
            </div>
            <div className="appline-circle-text">eCommerce</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLineSection;
