import React from "react";

const AboutGreenSection = () => {

  return (
    <div className="dds-about-green-container">
      <div className="dds-the-about-container" style={{textAlign: "right"}}>
        <div className="dds-about-green-content">
          <div className="dds-about-green-text">
            <h3>Client / Server Software</h3><br />
            <label>Solve your admin and data management headaches with bespoke desktop and server applications. Many internal processes lose companies time and money which could be otherwise better utilised. We make specifically designed systems to fit your business practises.</label>
          </div>
          <div className="dds-about-green-text">
            <h3>Free Consultancy</h3><br />
            <label>Sometimes you need a bit of outside help to look at things with fresh eyes. You know your business and its requirements best, but you may not have the in-house technical knowledge to make the most of every opportunity or solve every problem that arises. Contact us today to schedule a phone call with one of our team.</label>
          </div>
          <div className="dds-about-green-text">
            <h3>Big Data</h3><br />
            <label>Do you have large amounts of data that you want to mine but don’t know where to start? Or perhaps you’re collecting data across multiple technologies/channels and want to merge them in a centralised location so that you can cross analyse information. Well that’s what we love doing; tell us your problem and our experts thrive on producing systems that display your data in a way that makes your business better.</label>
          </div>
        </div>
        <label style={{fontSize: "20px"}}>{"</About>"}</label>
      </div>
    </div>
  );
}

export default AboutGreenSection;
