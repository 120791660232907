import React from "react";

const AppLogoSection = () => {

  return (
    <div className="dds-the-about-container">
      <div className="dds-app-logo-content">
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610034/DDS/1.png" alt="html-5" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">HTML 5</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610034/DDS/2.png" alt="css-3" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">CSS 3</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/3.png" alt="javascript" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Javascript</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/4.png" alt="sql-server" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">SQL Server</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/5.png" alt="my-sql" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">My SQL</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/6.png" alt="jquery" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">jQuery</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/7.png" alt="bootstrap" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Bootstrap</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/8.png" alt="alpha-anywhere" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Alpha</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/9.png" alt="ms-acecess" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Access</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/10.png" alt="ms-excel" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Excel</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/11.png" alt="wordpress" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Wordpress</div>
          </div>
        </div>
        <div className="app-logos">
          <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610035/DDS/12.png" alt="magneto" />
          <div className="dds-app-hover-logos">
            <div className="hover-logos-text">Magneto</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLogoSection;
