import React from "react";
import AboutSection from "../component/about/AboutSection";
import AppLineSection from "../component/about/AppLineSection";
import AppLogoSection from "../component/about/AppLogoSection";
import AboutGreenSection from "../component/about/AboutGreenSection";

const AboutPage = () => {

  return (
    <div className="dds-aboutpage-container">
      <AboutSection />
      <AppLineSection />
      <AppLogoSection />
      <AboutGreenSection />
    </div>
  );
}

export default AboutPage;
