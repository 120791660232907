import Axios from "axios";

export const TemplateEmail = async (name, email, message) => {
  let bodyOne = {
    // api_key: smtp2goKey,
    api_key: "api-ADA747C221F411ED9228F23C91BBF4A0",
    to: ["<sales@datadrivensystems.co.uk>"],
    cc: [],
    bcc: [],
    sender: "DDS Email System <sales@datadrivensystems.co.uk>",
    subject: "Customer Email <"+ name +">",
    html_body: `
    <div class="es-wrapper-color" style="background-color: #fafafa">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="
            border-collapse: collapse;
            border-spacing: 0px;
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background-repeat: repeat;
            background-position: center top;
        ">
            <tr style="border-collapse: collapse">
                <td valign="top" style="padding-top: 5vh; margin: 0">
                    <table class="es-header-body" cellspacing="0" cellpadding="0" align="center" style="
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: #e6ebef;
                        width: 600px;
                    ">
                        <tr style="border-collapse: collapse">
                            <td colspan="3" align="left" bgcolor="#ffffff" style="padding: 25px; margin: 0; background-color: #ffffff">
                                <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610033/DDS/logo.png"
                                    alt="dds-logo"
                                    style="display: block;
                                    border: 0;
                                    outline: none;
                                    text-decoration: none;
                                    width: 150px;
                                    -ms-interpolation-mode: bicubic;"
                                    width="190" 
                                />
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td align="left" bgcolor="#ffffff" style="width: 14%; padding: 0px 5px 10px 25px; margin: 0; background-color: #ffffff">
                                From
                            </td>
                            <td align="left" bgcolor="#ffffff" style="width: 1%; padding-bottom: 10px; margin: 0; background-color: #ffffff">
                                :
                            </td>
                            <td align="left" bgcolor="#ffffff" style="width: 85%; padding: 0px 25px 10px 5px; margin: 0; background-color: #ffffff">
                                `+ name +`
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td align="left" bgcolor="#ffffff" style="width: 14%; padding: 0px 5px 10px 25px; margin: 0; background-color: #ffffff">
                                Email
                            </td>
                            <td align="left" bgcolor="#ffffff" style="width: 1%; padding-bottom: 10px; margin: 0; background-color: #ffffff">
                                :
                            </td>
                            <td align="left" bgcolor="#ffffff" style="width: 85%; padding: 0px 25px 10px 5px; margin: 0; background-color: #ffffff">
                                `+ email +`
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td align="left" bgcolor="#ffffff" style="width: 14%; padding: 15px 5px 10px 25px; margin: 0; background-color: #ffffff">
                                Message
                            </td>
                            <td colspan="2" align="left" bgcolor="#ffffff" style="padding-block: 15px 10px; margin: 0; background-color: #ffffff">
                                :
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td colspan="3" align="left" bgcolor="#ffffff" style="padding: 0px 25px 25px 25px; text-align: justify; margin: 0; background-color: #ffffff">
                                `+ message +`
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
      `,
  };

  let bodyTwo = {
    // api_key: smtp2goKey,
    api_key: "api-ADA747C221F411ED9228F23C91BBF4A0",
    to: ["<"+ email +">"],
    cc: [],
    bcc: [],
    sender: "Data Driven Systems <sales@datadrivensystems.co.uk>",
    subject: "Thankyou for contacting us",
    html_body: `
    <div class="es-wrapper-color" style="background-color: #fafafa">
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" style="
            border-collapse: collapse;
            border-spacing: 0px;
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background-repeat: repeat;
            background-position: center top;
        ">
            <tr style="border-collapse: collapse">
                <td valign="top" style="padding-top: 5vh; margin: 0">
                    <table class="es-header-body" cellspacing="0" cellpadding="0" align="center" style="
                        border-collapse: collapse;
                        border-spacing: 0px;
                        background-color: #e6ebef;
                        width: 600px;
                    ">
                        <tr style="border-collapse: collapse">
                            <td align="left" bgcolor="#ffffff" style="padding: 25px; margin: 0; background-color: #ffffff">
                                <img src="https://res.cloudinary.com/datadriven/image/upload/v1448610033/DDS/logo.png"
                                    alt="dds-logo"
                                    style="display: block;
                                    border: 0;
                                    outline: none;
                                    text-decoration: none;
                                    width: 150px;
                                    -ms-interpolation-mode: bicubic;"
                                    width="190" 
                                />
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td align="left" bgcolor="#ffffff" style="width: 14%; padding: 0px 5px 10px 25px; margin: 0; background-color: #ffffff">
                                <p style="font-weight: bold;">Thankyou for contacting us</p>
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td colspan="3" align="left" bgcolor="#ffffff" style="padding: 0px 25px 0px 25px; text-align: justify; margin: 0; background-color: #ffffff">
                                We will notify you as soon as possible regarding the email you sent. 
                            </td>
                        </tr>
                        <tr style="border-collapse: collapse;">
                            <td colspan="3" align="left" bgcolor="#ffffff" style="padding: 25px; text-align: justify; margin: 0; background-color: #ffffff">
                                <p style="font-weight: bold;">Regards,</p>
                                DDS Team 
                            </td>
                        </tr>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
      `,
  };

  await Axios
    .post("https://api.smtp2go.com/v3/email/send", bodyOne)
    .then((response) => {
      if (response) {
        //window.location.reload(true)
      }
    })
    .catch((err) => {});

    await Axios
    .post("https://api.smtp2go.com/v3/email/send", bodyTwo)
    .then((response) => {
      if (response) {
        //window.location.reload(true)
      }
    })
    .catch((err) => {});
};

export default TemplateEmail;
