import React from "react";
import AboutPage from "./AboutPage";
import TeamPage from "./TeamPage";
import ContactPage from "./ContactPage";
import FooterSection from "../component/FooterSection";
import { NavLink } from "react-router-dom";

const HomePage = () => {
  
  // const navHighlighter = (e) => {
  //   const sections = document.querySelectorAll(".dds-homepage-content[id]");
  //   var scrollDistance = e.currentTarget.scrollTop + 85;

  //   sections.forEach((current) => {
  //     var sectionHeight = current.offsetHeight;
  //     var sectionTop = current.getBoundingClientRect().top;
  //     var sectionName = current.getAttribute("name");
  //     var menuButton = document.getElementById(sectionName+"-menu-button");

  //     console.log(scrollDistance, sectionHeight, sectionTop, sectionTop + sectionHeight)

  //     if(menuButton) {
  //       if (scrollDistance >= sectionTop && scrollDistance <= sectionTop + sectionHeight) {
  //         menuButton.style.opacity = 1
  //       } else {
  //         menuButton.style.opacity = 0.7
  //       }
  //     }
  //   });
  // }

  return (
    <div className="dds-homepage-container" 
      // onScroll={(e) => navHighlighter(e)}
    >
      <div id="dds-home-section" className="dds-homepage-content" name="home">
        <div className="bg-transparent" />
        <div className="dds-home-content">
          <h4>Welcome to</h4>
          <h1>DATA DRIVEN SYSTEMS</h1>
          We provide systems that deliver accessible, high quality information and analysis to enable informed decision-making.
        </div>
      </div>
      <div id="dds-about-section" className="dds-homepage-content" name="about">
        <AboutPage />
      </div>
      <div className="dds-homepage-portfolio">
        <div className="dds-home-portfolio-content">
          <div className="open-portfolio">{"<Portfolio>"}</div>
          <h3>CHECK OUT OUR PORTFOLIO</h3>
          <NavLink to="/portfolio">
            <button>VIEW PORTFOLIO</button>
          </NavLink>
          <div className="close-portfolio">{"</Portfolio>"}</div>
        </div>
      </div>
      <div id="dds-team-section" className="dds-homepage-content" name="team">
        <TeamPage />
      </div>
      <div className="dds-homepage-volunteer">
        <blockquote>
          <p>We have volunteered to help Code.org  Why don't you?  <a href="https://code.org/volunteer" target="_blank" rel="noopener noreferrer">https://code.org/volunteer</a></p>
          <label>— "You’re one of the first engineers to sign up for the Hour of Code — <b>thank you.</b>"</label>
        </blockquote>
      </div>
      <div id="dds-contact-section" className="dds-homepage-content" name="contact">
        <ContactPage />
      </div>
      <FooterSection />
    </div>
  );
}

export default HomePage;
