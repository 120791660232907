import React, { useState } from "react";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import TemplateEmail from "./TemplateEmail";
import { Modal, Spin } from "antd";

const SendEmailSection = () => {

  const [modalSentEmail, setModalSentEmail] = useState(false);
  const [modalText, setModalText] = useState("Sending Email");
  const [buttonHidden, setButtonHidden] = useState(true);
  const [checkName, setCheckName] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkMessage, setCheckMessage] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");

  const closeModalSentEmail = () => {
    setModalSentEmail(false)
    setButtonHidden(true)
    setModalText("Sending Email . . .")
  } 

  const sendEmail = async () => {
    let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var name = document.getElementById("dds-name-input").value
    var email = document.getElementById("dds-email-input").value
    var message = document.getElementById("dds-message-text").value
    var emailCheck = true

    name?setCheckName(false):setCheckName(true)
    email?setCheckEmail(false):setCheckEmail(true)
    message?setCheckMessage(false):setCheckMessage(true)
    
    if(email){
      if(emailFormat.test(email)){
        setCheckEmail(false)
        setEmailMsg("*required field")
      }else{
        setCheckEmail(true)
        setEmailMsg("*invalid email format")
        emailCheck = false
      }
    }
    else{
      setEmailMsg("*required field")
    }

    if(name && email && message && emailCheck) {
        setModalSentEmail(true)
        const emailTemplate = await TemplateEmail(name, email, message).then((response) => {
          setModalText("Email Sent")
          setButtonHidden(false)
        })
        // if (emailTemplate === undefined) {
          
        // }
    }
  }

  return (
    <div className="dds-the-contact-container">
      <div className="dds-send-email-container">
        <div className="dds-form-email">
          <input id="dds-name-input" type="text" placeholder="Name"></input>
          {checkName && <div className="required-text-sent-email">
            *required field
          </div>}
          <input id="dds-email-input" type="email" placeholder="Email"></input>
          {checkEmail && <div className="required-text-sent-email" style={{top: "calc(68px + 1.75rem)"}}>
            {emailMsg}
          </div>}
          <textarea id="dds-message-text"></textarea>
          {checkMessage && <div className="required-text-sent-email" style={{top: "calc(143px + 3.25rem)"}}>
            *required field
          </div>}
          <button onClick={() => sendEmail()}>SEND MESSAGE</button>
        </div>
        <div className="dds-email-text">
          <div className="dds-email-address">
            <FaEnvelope className="dds-email-icon" />
            <a href="mailto:sales@datadrivensystems.co.uk">sales@datadrivensystems.co.uk</a>
          </div>
          <div className="dds-location-address">
            <FaMapMarkerAlt className="dds-email-icon" />
            <p>20-22 Wenlock Road, London, N1 7GU</p>
          </div>
        </div>
      </div>
      <div className="dds-close-contact">
        <label style={{fontSize: "20px"}}>{"</Contact>"}</label>
      </div>
      <Modal
        title={null}
        centered
        open={modalSentEmail}
        width={400}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <div style={{textAlign: "center"}}>
          <b>{modalText}</b> &nbsp; <Spin size="small" hidden={!buttonHidden}/>
          <div hidden={buttonHidden}>
            <button className="dds-close-button-modal-sent-email" onClick={() => closeModalSentEmail()}>Close</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SendEmailSection;
