import React from "react";
import ContactSection from "../component/contact/ContactSection";
import SendEmailSection from "../component/contact/SendEmailSection";

const ContactPage = () => {

  return (
    <div className="dds-contactpage-container">
      <ContactSection />
      <SendEmailSection />
    </div>
  );
}

export default ContactPage;
