import React, { useEffect } from "react";
import FooterSection from "../../component/FooterSection";
import { IoMdClose } from "react-icons/io"

const PortfolioPage = () => {

  const filters = document.getElementsByClassName("filter-text")
  const web = document.getElementsByClassName("porto-web")
  const logo = document.getElementsByClassName("porto-logo")
  const design = document.getElementsByClassName("porto-design")

  const openImage = (type, img) => {
    var popup = document.getElementById("portfolio-open-image")
    var image = document.getElementById("portfolio-open-image-div")
    popup.style.display = "flex"
    image.innerHTML = "<img src='"+ img + "' class='image-"+ type +"' alt='popup-image'/>"
  }

  const closeImage = () => {
    var popup = document.getElementById("portfolio-open-image")
    var image = document.getElementById("portfolio-open-image-div")
    popup.style.display = "none"
    image.innerHTML = " "
  }

  const filterAll = () => {
    var i = 0;

    for(i=0; i<filters.length; i++) {
      filters[i].style.color = "#555555"
      filters[i].style.borderColor = "#FFFFFF"
    }

    filters[0].style.color = "#006D81"
    filters[0].style.borderColor = "#006D81"

    for(i=0; i<logo.length; i++) {
      logo[i].style.display = "block"
    }
    for(i=0; i<design.length; i++) {
      design[i].style.display = "block"
    }
    for(i=0; i<web.length; i++) {
      web[i].style.display = "block"
    }
  }

  const filterWeb = () => {
    var i = 0;

    for(i=0; i<filters.length; i++) {
      filters[i].style.color = "#555555"
      filters[i].style.borderColor = "#FFFFFF"
    }

    filters[1].style.color = "#006D81"
    filters[1].style.borderColor = "#006D81"

    for(i=0; i<logo.length; i++) {
      logo[i].style.display = "none"
    }
    for(i=0; i<design.length; i++) {
      design[i].style.display = "none"
    }
    for(i=0; i<web.length; i++) {
      web[i].style.display = "block"
    }
  }

  const filterLogo = () => {
    var i = 0;

    for(i=0; i<filters.length; i++) {
      filters[i].style.color = "#555555"
      filters[i].style.borderColor = "#FFFFFF"
    }

    filters[2].style.color = "#006D81"
    filters[2].style.borderColor = "#006D81"

    for(i=0; i<design.length; i++) {
      design[i].style.display = "none"
    }
    for(i=0; i<web.length; i++) {
      web[i].style.display = "none"
    }
    for(i=0; i<logo.length; i++) {
      logo[i].style.display = "block"
    }
  }

  const filterDesign = () => {
    var i = 0;

    for(i=0; i<filters.length; i++) {
      filters[i].style.color = "#555555"
      filters[i].style.borderColor = "#FFFFFF"
    }

    filters[3].style.color = "#006D81"
    filters[3].style.borderColor = "#006D81"

    for(i=0; i<logo.length; i++) {
      logo[i].style.display = "none"
    }
    for(i=0; i<web.length; i++) {
      web[i].style.display = "none"
    }
    for(i=0; i<design.length; i++) {
      design[i].style.display = "block"
    }
  }

  useEffect(() => {
    filters[0].style.color = "#006D81"
    filters[0].style.borderColor = "#006D81"
  }, [filters]);

  return (
    <div className="dds-portfoliopage-container">
      <div id="portfolio-open-image">
        <div id="portfolio-open-image-div"></div>
        <div className="close-button"><IoMdClose className="close-icon" onClick={() => closeImage()}/></div>
      </div>
      <div className="dds-portfoliopage-content">
        <div className="open-portfolio">{"<Portfolio>"}</div>
        <div className="dds-portfolio-filters">
          <div className="filter-text" onClick={() => filterAll()}>All</div>
          <div className="filter-text" onClick={() => filterWeb()}>Websites</div>
          <div className="filter-text" onClick={() => filterLogo()}>Logo and Branding</div>
          <div className="filter-text" onClick={() => filterDesign()}>Design</div>
        </div>
        <div className="portfolio-gallery-masonry-container">
          <div id="portfolio-small-square" className="porto-design" onClick={() => openImage("square",  "https://res.cloudinary.com/datadriven/image/upload/v1508561438/Cover/22.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561438/Cover/22.jpg" alt="web-design-purchase-order" />
            <div className="portfolio-img-text">
              <h3>Purchase Order</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-logo" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/31.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/31.jpg" alt="web-design-web-design" />
            <div className="portfolio-img-text">
              <h3>Web Design</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-logo" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/30.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/30.jpg" alt="web-design-list-style" />
            <div className="portfolio-img-text">
              <h3>List Style</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/21.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/21.jpg" alt="web-design-mood-board" />
            <div className="portfolio-img-text">
              <h3>Mood Board</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/32.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/32.jpg" alt="web-design-form-screen" />
            <div className="portfolio-img-text">
              <h3>Form Screen</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/33.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/33.jpg" alt="web-design-ux-control" />
            <div className="portfolio-img-text">
              <h3>UX Control</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561196/Cover/34.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561196/Cover/34.jpg" alt="web-design-forest" />
            <div className="portfolio-img-text">
              <h3>Forest</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561194/Cover/24.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561194/Cover/24.jpg" alt="web-design-wine-movement" />
            <div className="portfolio-img-text">
              <h3>Wine Movement</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/26.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/26.jpg" alt="web-design-tft" />
            <div className="portfolio-img-text">
              <h3>TFT</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1508561197/Cover/40.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561197/Cover/40.jpg" alt="web-design-grana" />
            <div className="portfolio-img-text">
              <h3>Grana</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1508561194/Cover/25.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561194/Cover/25.jpg" alt="web-design-xflat" />
            <div className="portfolio-img-text">
              <h3>Xflat</h3>
              <p>Web Design, Design</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/18.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/18.jpg" alt="web-design-alphasnip" />
            <div className="portfolio-img-text">
              <h3>Alphasnip</h3>
              <p>Web Design, Icon</p>
            </div>
          </div>
          <div id="portfolio-small" className="porto-web" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/29.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/29.jpg" alt="web-design-portal" />
            <div className="portfolio-img-text">
              <h3>Portal</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small" className="porto-web" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/19.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561193/Cover/19.jpg" alt="web-design-trip-builder-pro" />
            <div className="portfolio-img-text">
              <h3>Trip Builder Pro</h3>
              <p>Web Design, Logo, Icon</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-design" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847452/Cover/Nissan_View_1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847452/Cover/Nissan_View_1.jpg" alt="web-design-nissan" />
            <div className="portfolio-img-text">
              <h3>Nissan</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small" className="porto-web" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/28.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1508561195/Cover/28.jpg" alt="web-design-bcm-london" />
            <div className="portfolio-img-text">
              <h3>BCM London</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-small" className="porto-design" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1696847445/Cover/David_1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847445/Cover/David_1.jpg" alt="web-design-david-portfolio" />
            <div className="portfolio-img-text">
              <h3>David Portfolio</h3>
              <p>Web Design, Icon</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847358/Cover/2_2.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847358/Cover/2_2.jpg" alt="web-design-data-driven-systems" />
            <div className="portfolio-img-text">
              <h3>Data Driven Systems</h3>
              <p>Web Design</p>
            </div>
          </div>
          <div id="portfolio-horizontal" className="porto-logo" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1696847446/Cover/icons_1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847446/Cover/icons_1.jpg" alt="design-icon-icons-bagoes" />
            <div className="portfolio-img-text">
              <h3>Icons Bagoes</h3>
              <p>Design Icon</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-logo porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1696847453/Cover/thumbnail.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847453/Cover/thumbnail.jpg" alt="web-design-retro" />
            <div className="portfolio-img-text">
              <h3>Retro</h3>
              <p>Web Design, Logo</p>
            </div>
          </div>
          <div id="portfolio-horizontal" className="porto-logo" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1696847451/Cover/motor-icons.png")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847451/Cover/motor-icons.png" alt="design-icon-icon-makelar" />
            <div className="portfolio-img-text">
              <h3>Icon Makelar</h3>
              <p>Design Icon</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-logo" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847455/Cover/wine_1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847455/Cover/wine_1.jpg" alt="design-logo-icon-wine-apps" />
            <div className="portfolio-img-text">
              <h3>Icon Wine Apps</h3>
              <p>Design Logo</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-logo porto-design" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847449/Cover/Makelar.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847449/Cover/Makelar.jpg" alt="web-design-makelar-motor" />
            <div className="portfolio-img-text">
              <h3>Makelar Motor</h3>
              <p>Web Design, Icon</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-design" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847448/Cover/kittys_1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847448/Cover/kittys_1.jpg" alt="design-icon-kitys-cake" />
            <div className="portfolio-img-text">
              <h3>Kitty's Cake</h3>
              <p>Design Icon</p>
            </div>
          </div>
          <div id="portfolio-horizontal" className="porto-logo" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1696847398/Cover/12.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847398/Cover/12.jpg" alt="design-icon-icons" />
            <div className="portfolio-img-text">
              <h3>Icons</h3>
              <p>Design Icon</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-web" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847369/Cover/9.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847369/Cover/9.jpg" alt="wordpress-logo-two-dirty-canons" />
            <div className="portfolio-img-text">
              <h3>Two Dirty Canons</h3>
              <p>Wordpress, Logo</p>
            </div>
          </div>
          <div id="portfolio-horizontal" className="porto-web" onClick={() => openImage("horizontal", "https://res.cloudinary.com/datadriven/image/upload/v1696847372/Cover/10.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847372/Cover/10.jpg" alt="alpha-anywhere-bagoes" />
            <div className="portfolio-img-text">
              <h3>Bagoes</h3>
              <p>Alpha Anywhere</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-web" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1696847360/Cover/5.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847360/Cover/5.jpg" alt="alpha-anywhere-day-books" />
            <div className="portfolio-img-text">
              <h3>Day Books</h3>
              <p>Alpha Anywhere</p>
            </div>
          </div>
          <div id="portfolio-vertical" className="porto-web porto-design" onClick={() => openImage("vertical", "https://res.cloudinary.com/datadriven/image/upload/v1696847359/Cover/4.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847359/Cover/4.jpg" alt="alpha-anywhere-wine-app" />
            <div className="portfolio-img-text">
              <h3>Wine App</h3>
              <p>Alpha Anywhere, Logo</p>
            </div>
          </div>
          <div id="portfolio-big-square" className="porto-web porto-logo" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847362/Cover/7.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847362/Cover/7.jpg" alt="logo-tech-shelf" />
            <div className="portfolio-img-text">
              <h3>Tech Self</h3>
              <p>Logo, Magneto</p>
            </div>
          </div>
          <div id="portfolio-small-square" className="porto-logo" onClick={() => openImage("square", "https://res.cloudinary.com/datadriven/image/upload/v1696847357/Cover/1.jpg")}>
            <img src="https://res.cloudinary.com/datadriven/image/upload/v1696847357/Cover/1.jpg" alt="design-warranty-logger" />
            <div className="portfolio-img-text">
              <h3>Warranty Logger</h3>
              <p>Design</p>
            </div>
          </div>
        </div>
        <div className="close-portfolio">{"</Portfolio>"}</div>
      </div>
      <FooterSection />
    </div>
  );
}

export default PortfolioPage;
