import React from "react";
import { Navigate, Route, Routes } from 'react-router';
import './App.css';
import HomePage from "./pages/HomePage";
import TopBar from "./component/topbar/TopBar";
import JobsPage from "./pages/JobsPage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<TopBar />}>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/job' element={<JobsPage />}></Route>
        <Route path='/portfolio' element={<PortfolioPage />}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
