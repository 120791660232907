import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTwitter, } from "react-icons/fa";

const FooterSection = () => {

  const goToHome = () => {
    window.location.href = "/"
  }
  
  return (
    <div className="dds-footer-section">
      <label onClick={() => goToHome()}>DATA DRIVEN SYSTEMS</label>
      <div className="dds-footer-social-media">
        <a href="https://twitter.com/DDS_Officer" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://www.facebook.com/DataDrivenSystems" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://instagram.com/datadrivensystems.co.uk" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.pinterest.com/DDS_officer/" target="_blank" rel="noopener noreferrer">
          <FaPinterest />
        </a>
        <a href="https://id.linkedin.com/company/datadrivensystems-uk" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>
    </div>
  );
};

export default FooterSection;
