import React from "react";

const ContactSection = () => {

  return (
    <div className="dds-the-contact-container">
      <label style={{fontSize: "20px"}}>{"<Contact>"}</label>
      <div className="dds-contact-section-text">
        <h3>Get in touch</h3>
        <p>We invite you to email us and provide feedback on our service, offer suggestions or just say hello.</p>
      </div>
    </div>
  );
}

export default ContactSection;
