import React from "react";
import TeamSection from "../component/team/TeamSection";

const TeamPage = () => {

  return (
    <div className="dds-teampage-container">
      <TeamSection />
    </div>
  );
}

export default TeamPage;
